<template>
  <div class="row">
    <div class="col-12">
      <card class="shadow" body-classes="standard-card-body">
        <div slot="header"><h4 class="card-title text-capitalize">{{ $t('page.provider_data') }}</h4></div>
        <ul class="list-group list-group-flush">
          <list-group-item-component :label="$t('common.status')" :value="providerImport.status"/>
          <list-group-item-component
            :label="$t('datatable.date')"
            :value="`${$options.filters.date(providerImport.created_date)} ${providerImport.created_time}`"
          />
        </ul>
      </card>
    </div>
    <div class="col-12">
      <card class="shadow" body-classes="standard-card-body">
        <provider-import-detail-datatable :import-id="importId"/>
      </card>
    </div>
  </div>

</template>

<script>
import {endpoints} from "@/routes/endpoints";
import {Table, TableColumn} from 'element-ui';
import ProviderImportDetailDatatable from "@/pages/Registries/datatables/ProviderImportDetailDatatable";
import ProviderImport from "@/models/providerImport";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import LabelThemeComponent from "@/components/LabelThemeComponent";

export default {
  name: "ProviderImportDetailShowPage",
  components: {
    LabelThemeComponent,
    ListGroupItemComponent,
    ProviderImportDetailDatatable,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    OctoIcon,
  },
  data() {
    return {
      endpoints: endpoints,
      importId: this.$route.params.id,
      providerImport: this.$_.cloneDeep(ProviderImport),
    }
  },
  beforeMount() {
    this.$fullLoading.show();
    this.$api.get(endpoints.PROVIDER_IMPORT_SHOW.replace('{id}', this.importId))
      .then((resp) => {
        this.providerImport = resp.data.data;
      })
      .catch(() => {
        this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
      })
      .finally(() => {
        this.$fullLoading.hide();
      })
  }
}
</script>

<style scoped>

</style>
