<template>
  <div>
    <octo-table
      show-filters
      :action="endpoints.DATATABLES.providerImportData.replace('{id}',importId)"
      :fields="fields"
      :filters="filters"
      :name="name"
      @onResetFilters="filters = {}"
    >

      <template v-slot:date="data">
        <div class="text-uppercase small">{{ data.row.date | date }}</div>
      </template>

      <template v-slot:registry_type="data">
        <div class="text-uppercase small">{{ data.row.registry_type }}</div>
      </template>

      <template v-slot:name="data">
        <div class="text-uppercase small">{{ data.row.name }}</div>
      </template>

      <template v-slot:surname="data">
        <div class="text-uppercase small">{{ data.row.surname }}</div>
      </template>

      <template v-slot:course_code="data">
        <div class="small">{{ courses[data.row.course_id] | optional('code') }}</div>
      </template>

      <template v-slot:course_slug="data">
        <div class="small">{{ courses[data.row.course_id] | optional('slug') }}</div>
      </template>

      <template v-slot:provider_acronym="data">
        <div class="small">{{ data.row | optional('provider.acronym') }}</div>
      </template>

      <template v-slot:provider_slug="data">
        <div class="small">{{ data.row | optional('provider.slug') }}</div>
      </template>

      <template v-slot:email="data">
        <div class="text-uppercase small">{{ data.row | optional('email') }}</div>
      </template>

      <template v-slot:registry_id="data">
        <div class="text-uppercase small">{{ data.row | optional('registry.id') | udid }}</div>
      </template>

      <template v-slot:lead_id="data">
        <div class="text-uppercase small">{{ data.row | optional('lead_id') | udid }}</div>
      </template>

      <template v-slot:actions="data">
        <div class="d-flex justify-content-end">
          <base-button link icon class="mx-1" size="sm" @click="showDetailData(data.row)">
            <octo-icon icon="right-arrow"/>
          </base-button>
        </div>
      </template>
    </octo-table>

    <modal centered :show.sync="showModalDetail" modalClasses="modal-lg" bodyClasses="p-1">
      <provider-import-detail-modal-content :provider-import-data="currentDetailSelected"/>
    </modal>
  </div>
</template>

<script>
import OctoTable from "@/components/octo-table/OctoTable";
import {endpoints} from "@/routes/endpoints";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import Modal from "@/components/Modal";
import ProviderImportData from "@/models/providerImportData";
import ProviderImportDetailModalContent from "@/pages/Registries/components/ProviderImportDetailModalContent";
import {mapGetters} from "vuex";

export default {
  name: "ProviderImportDetailDatatable",
  components: {
    ProviderImportDetailModalContent,
    OctoTable,
    OctoIcon,
    Modal,
  },
  props: {
    importId: {
      type: Number | String,
      default: 0,
    },
  },

  data() {
    return {
      name: 'ProviderImportDetailDatatable',
      endpoints: endpoints,
      showModalDetail: false,
      filters: {},
      fields: [
        {prop: 'name', label: 'name', slot: true, align: 'center', width: 150, fixed: true},
        {prop: 'surname', label: 'surname', slot: true, align: 'center', width: 150, fixed: true},
        {prop: 'date', label: 'date', slot: true, align: 'center', width: 100},
        {prop: 'registry_type', label: 'registry_type', slot: true, align: 'center', width: 150},
        {prop: 'course_code', label: 'course_code', slot: true, align: 'center', width: 150},
        {prop: 'course_slug', label: 'course_slug', slot: true, align: 'center', minWidth: 350},
        {prop: 'provider_acronym', label: 'provider_acronym', slot: true, align: 'center', width: 200},
        {prop: 'provider_slug', label: 'provider_slug', slot: true, align: 'center', minWidth: 250},
        {prop: 'email', label: 'email', slot: true, align: 'center', minWidth: 250},
        {prop: 'registry_id', label: 'registry_id', slot: true, align: 'center', width: 100},
        {prop: 'lead_id', label: 'lead_id', slot: true, align: 'center', width: 100},
        {prop: 'actions', label: 'actions', slot: true, fixed: 'right', width: 60},
      ],
      currentDetailSelected: this.$_.cloneDeep(ProviderImportData),
    }
  },
  beforeMount() {
    this.filters = this.getFilters(this.name);
  },
  computed: {
    ...mapGetters({
      getFilters: 'common/datatableFilters',
      courses: 'common/allCourses',
    }),
  },
  methods: {
    showDetailData: function (data) {
      this.currentDetailSelected = data;
      this.showModalDetail = true;
    }
  }
}
</script>

<style scoped>

</style>
