const ProviderImportData = {
  id: null,
  email: null,
  name: null,
  surname: null,
  provider: {},
  registry: null,
  data: {},
  price: 0,
  date: null,
  course: {},
  course_id: null,
  provider_id: null
};

export default ProviderImportData
