<template>
  <li class="standard-section-title">
    <h4 class="title-section mb-1" :class="themeClass">
      {{ label }}
    </h4>
  </li>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "ListGroupTitleSectionComponent",
  props: {
    label: {
      type: String | Number,
      default: null
    },

  },
  computed: {
    ...mapGetters({
      themeColor: 'auth/themeColor',
    }),
    themeClass: function () {
      switch (this.themeColor) {
        case 'primary':
          return 'text-primary';
        case 'vue':
        case 'green':
          return 'text-success';
        case 'red':
          return 'text-danger';
        case 'orange':
          return 'text-warning';
        case 'blue':
          return 'text-info';
        default:
          return 'text-primary';
      }
    }
  }
}
</script>

<style scoped>

</style>
