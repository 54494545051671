<template>
  <div>
    <ul class="list-group list-group-flush">
      <list-group-title-section-component :label="$t('common.registry')"/>
      <li class="list-group-item p-2">
        <div class="row">
          <div class="col col-6">
            <div :class="itemClass">
              <div>{{ $t('fields.firstname') }}</div>
              <label-theme-component>
                {{ providerImportData | optional('registry.name') }}
              </label-theme-component>
            </div>
          </div>
          <div class="col col-6">
            <div :class="itemClass">
              <div>{{ $t('fields.lastname') }}</div>
              <label-theme-component>
                {{ providerImportData | optional('registry.surname') }}
              </label-theme-component>
            </div>
          </div>
          <div class="col col-6">
            <div :class="itemClass">
              <div>{{ $t('fields.gender') }}</div>
              <label-theme-component>
                {{ providerImportData | optional('registry.gender') }}
              </label-theme-component>
            </div>
          </div>
          <div class="col col-6">
            <div :class="itemClass">
              <div>{{ $t('fields.birth_date') }}</div>
              <label-theme-component>
                {{ providerImportData | optional('registry.birth_date') | date }}
              </label-theme-component>
            </div>
          </div>
          <div class="col col-6">
            <div :class="itemClass">
              <div>{{ $t('fields.codfis') }}</div>
              <label-theme-component>
                {{ providerImportData | optional('registry.codfis') }}
              </label-theme-component>
            </div>
          </div>
        </div>
      </li>
      <list-group-title-section-component :label="$t('common.info')"/>
      <li class="list-group-item p-2">
        <div class="row">
          <div class="col col-6">
            <div :class="itemClass">
              <div>{{ $t('common.provider') }}</div>
              <label-theme-component>
                {{ providerImportData | optional('provider.name') }}
              </label-theme-component>
            </div>
          </div>
          <div class="col col-6">
            <div :class="itemClass">
              <div>{{ $t('common.course') }}</div>
              <label-theme-component>
                {{ providerImportData | optional('course.title') }}
              </label-theme-component>
            </div>
          </div>
        </div>
      </li>
      <list-group-title-section-component :label="$t('common.show_data_row')"/>
      <li class="list-group-item p-2">
        <div class="row">
          <div class="col col-6" v-for="(data, key) in providerImportData.data" v-bind:key="key">
            <div :class="itemClass">
              <div>{{ key }}</div>
              <label-theme-component>
               {{data}}
              </label-theme-component>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import ProviderImportData from "@/models/providerImportData";
import ListGroupTitleSectionComponent from "@/components/ListGroupTitleSectionComponent";
import LabelThemeComponent from "@/components/LabelThemeComponent";

export default {
  name: "ProviderImportDetailModalContent",
  components: {LabelThemeComponent, ListGroupTitleSectionComponent},
  data() {
    return {
      itemClass:"d-flex justify-content-between small text-uppercase"
    }
  },
  props: {
    providerImportData: {
      type: Object,
      default: () => this.$_.cloneDeep(ProviderImportData),
    },
  },
}
</script>

<style scoped>

</style>
